import { useDocumentVisibility } from "ahooks";
import { LoaderCircle, TriangleAlert } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { GoogleCalendarIcon } from "@/app/components/icons";
import { useConfidenceUserContext } from "@/app/context";
import { CalendarProviderName, bindGoogleAccountUserAccountsGoogleGet } from "@/generated";
import { defineViewId } from "@/lib/utils";

import { SettingItem } from "../components/settings";
import { ActivateTag, ActiveTag, ConnectedTag, GoToConnectTag } from "./components";
import { CONNECT_CALLBACK_QUERY_FLAG } from "./switch/[provider]/page";

function GoogleCalendarItemWithConnect() {
  const documentVisibility = useDocumentVisibility();
  const refreshOnNextVisibleRef = useRef(false);
  const [generating, setGenerating] = useState(true);
  const [connectUrl, setConnectUrl] = useState("");
  const abortController = useRef<AbortController | null>(null);
  const generateConnectUrl = useCallback(async () => {
    try {
      if (abortController.current) {
        abortController.current.abort();
      }
      setGenerating(true);
      abortController.current = new AbortController();
      const { url } = await bindGoogleAccountUserAccountsGoogleGet(
        {
          callback_url: `${window.location.origin}/settings/calendars/switch/google?${CONNECT_CALLBACK_QUERY_FLAG}=1`,
        },
        abortController.current.signal,
      );
      refreshOnNextVisibleRef.current = true;
      setConnectUrl(new URL(url).toString());
      abortController.current = null;
    } catch (e) {
      //
    } finally {
      setGenerating(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    generateConnectUrl();
  }, [generateConnectUrl]);

  // TODO to be fix
  const refreshAccountCalendars = useCallback(() => {
    //
  }, []);
  const refreshAccountCalendarsRef = useRef(refreshAccountCalendars);

  useEffect(() => {
    if (refreshOnNextVisibleRef.current && documentVisibility === "visible") {
      refreshAccountCalendarsRef.current();
    }
  }, [documentVisibility]);

  if (generating) {
    return (
      <SettingItem
        icon={<GoogleCalendarIcon className="w-6" />}
        label="Google Calendar"
        suffix={<LoaderCircle width={16} className="animate-spin stroke-slate-400" />}
      />
    );
  }

  if (!connectUrl) {
    return (
      <SettingItem
        onClick={generateConnectUrl}
        icon={<GoogleCalendarIcon className="w-6" />}
        label="Google Calendar"
        suffix={
          <div className="flex flex-row items-center gap-1">
            <TriangleAlert width={16} className="stroke-red-500" />
            <span className="text-xs text-red-500">Error</span>
          </div>
        }
      />
    );
  }

  return (
    <SettingItem
      icon={<GoogleCalendarIcon className="w-6" />}
      label="Google Calendar"
      href={connectUrl}
      external
      suffix={<GoToConnectTag />}
    />
  );
}

export default function GoogleCalendarItem() {
  const { user } = useConfidenceUserContext();
  const googleAccount = useMemo(() => {
    if (!user.accounts) return null;
    return user.accounts.find((a) => a.provider === CalendarProviderName["google"]) ?? null;
  }, [user.accounts]);

  if (googleAccount) {
    return (
      <SettingItem
        icon={<GoogleCalendarIcon className="w-6" />}
        label=<p style={defineViewId(`setting-calendar-${googleAccount.provider}`)}>Google Calendar</p>
        description={<ConnectedTag>{googleAccount.account_name}</ConnectedTag>}
        href={`/settings/calendars/switch/${CalendarProviderName["google"]}`}
        suffix={user.current_account === CalendarProviderName["google"] ? <ActiveTag /> : <ActivateTag />}
      />
    );
  }
  return <GoogleCalendarItemWithConnect />;
}
