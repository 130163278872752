import { LoaderCircle } from "lucide-react";
import { useCallback, useState } from "react";

import { DolaIcon } from "@/app/components/icons";
import { useConfidenceUserContext } from "@/app/context";
import { CalendarProviderName, setDefaultCalendarUserCalendarsDefaultPut } from "@/generated";
import { toast } from "@/lib/utils";

import { SettingItem } from "../components/settings";
import { ActivateTag, ActiveTag, ConnectedTag } from "./components";

function DolaCalendarItemWithActiveAction() {
  const [activating, setActivating] = useState(false);
  const { refresh: refreshUser } = useConfidenceUserContext();
  const onClick = useCallback(async () => {
    if (window.confirm("Activate Dola Calendar?")) {
      try {
        setActivating(true);
        await setDefaultCalendarUserCalendarsDefaultPut({
          provider: CalendarProviderName["self_hosted"],
        });
        toast("Activated");
        refreshUser();
      } catch (e) {
        //
      } finally {
        setActivating(false);
      }
    }
  }, [refreshUser]);

  return (
    <SettingItem
      onClick={onClick}
      icon={<DolaIcon className="w-6" />}
      label={"Dola"}
      description={<p className="dola-gradient-text">Built-in, fast and easy to use</p>}
      suffix={
        activating ? (
          <div className="flex flex-row items-center gap-1">
            <LoaderCircle height={16} className="animate-spin stroke-slate-400" />
            <span className="text-xs text-slate-400">Activating</span>
          </div>
        ) : (
          <ActivateTag />
        )
      }
    />
  );
}

export default function DolaCalendarItem() {
  const { user } = useConfidenceUserContext();
  const isActive = user.current_account === CalendarProviderName["self_hosted"];
  if (isActive) {
    const dolaCalendar = user.accounts?.find((s) => s.provider === CalendarProviderName["self_hosted"])?.account_name;
    return (
      <SettingItem
        description={dolaCalendar ? <ConnectedTag>{`${dolaCalendar}@heydola.com`}</ConnectedTag> : null}
        icon={<DolaIcon className="w-6" />}
        label={"Dola"}
        suffix={<ActiveTag />}
      />
    );
  }
  return <DolaCalendarItemWithActiveAction />;
}
