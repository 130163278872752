import { BookOpenText } from "lucide-react";
import { createElement } from "react";
import { useTranslation } from "react-i18next";

import { AppleCalendarIcon, DolaIcon, GoogleCalendarIcon } from "@/app/components/icons";
import { PossibleUserRender, useConfidenceUserContext } from "@/app/context";
import { CalendarProviderName } from "@/generated";
import { defineViewId } from "@/lib/utils";

import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";
import DolaCalendarItem from "./calendar-dola";
import GoogleCalendarItem from "./calendar-google";
import { CommonLoading } from "./components";

function DolaActive() {
  return (
    <SettingGroup title="Current Active">
      <div className="flex flex-col items-center justify-center gap-2 border-b border-slate-200 p-4 text-center">
        <DolaIcon className="w-16" />
        <div className="dola-gradient-text text-md px-8">
          Developed by the Dola AI team, continuously integrating the latest features.
        </div>
      </div>
      <SettingItem
        icon={<BookOpenText width={16} className="" />}
        label={<p style={defineViewId("settings-calendar-dola-guide")}>Sync to your Calendar App</p>}
        href="/settings/calendars/guide"
      />
    </SettingGroup>
  );
}

function ThirdPartyActive({ provider }: { provider: CalendarProviderName }) {
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  return (
    <SettingGroup title="Current Active">
      <div className="flex flex-col items-center justify-center gap-4 p-4 text-center">
        {createElement(provider === CalendarProviderName["icloud"] ? AppleCalendarIcon : GoogleCalendarIcon, {
          className: "w-16",
        })}
        <div className="text-md px-8">{text(provider)}</div>
      </div>
    </SettingGroup>
  );
}

function CurrentActive() {
  const { user } = useConfidenceUserContext();
  const isDola = user.current_account === CalendarProviderName["self_hosted"];
  return isDola ? <DolaActive /> : <ThirdPartyActive provider={user.current_account} />;
}

export default function CalendarPage() {
  return (
    <SettingSubpage headerLeft={null} header={<p className="font-semibold view-id-[settings-calendars]">Calendars</p>}>
      <SettingGroups>
        <PossibleUserRender
          loading={
            <SettingGroup title="Current Active">
              <CommonLoading />
            </SettingGroup>
          }
          user={<CurrentActive />}
        />
        <SettingGroup title="All Calendar Accounts" suffix={<div></div>}>
          <PossibleUserRender
            loading={<CommonLoading />}
            user={
              <>
                <DolaCalendarItem />
                <GoogleCalendarItem />
              </>
            }
          />
        </SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
